<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import { postAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import RelatedProductsModel from './components/RelatedProductsModel'
import { getImgArr } from '@/utils/util'
import { unionBy } from 'lodash'
export default {
  name: 'investment-detail',
  data() {
    return {
      ...api.command.getState(),
      regionCode: [],
      relatedProductsKeys: [],
      // 关联商品
      relatedProducts: [],
    }
  },

  mounted() {
    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'regionCode',
    })
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '标题',
            type: 'input',
            cols: 12,
            key: 'title',
            props: {
              maxLength: 37,
            },
            rules: [
              {
                required: true,
                type: 'string',
                message: '请输入标题',
              },
            ],
          },
          {
            name: '排序',
            type: 'input',
            cols: 12,
            key: 'sort',
          },
          {
            name: '正文',
            type: 'textArea',
            key: 'content',
            cols: 24,
            rules: [
              {
                required: true,
                message: '请输入正文',
              },
            ],
          },
        ],
      }
    },
    getForm2() {
      return {
        title: '图片上传',
        type: 'cardForm',
        data: [
          {
            type: 'upload',
            key: 'bannerUrl',
            maxLength: 9,
            cols: 24,
            accept: '*',
            rules: [
              {
                required: true,
                message: '请选择图片/视频',
              },
            ],
          },
        ],
      }
    },
    getForm7() {
      return {
        type: 'cardForm',
        cols: 24,
        title: '发布设置',
        data: [
          {
            type: 'latlng',
            key: 'xxx',
            name: '定位',
            cols: 12,
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            colsType: {
              lg: 24,
              md: 24,
              sm: 24,
              xs: 24,
              xxl: 12,
              xl: 18,
            },
            gutter: 5,
            rules: [
              {
                required: true,
              },
            ],
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'regionCode',
                label: '地址',
                keyData: ['provinceCode', 'cityCode', 'areaCode'],
                nameKey: ['provinceName', 'cityName', 'areaName'],
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址',
                  },
                ],
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '地址',
                rules: [
                  {
                    required: true,
                    message: '请输入地址',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    // 关联商品表单
    getRelatedProductsForm() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        header: this.renderRelatedProductsFormTop,
        showRowSelect: false,
        showPagination: false,
        columns: [
          {
            dataIndex: 'bannerUrl',
            align: 'center',
            title: '商品图片',
            width: '20%',
            customRender: function (value) {
              const imgArr = getImgArr(value)
              return <img class="related-products-form-img" src={imgArr[0] || ''} />
            },
          },
          {
            dataIndex: 'name',
            align: 'center',
            title: '商品名称',
            width: '20%',
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: 180,
            title: '操作',
            type: 'buttonGroup',
            disablePermissionVerification: true,
            typeData: function ({ text, records, index }) {
              return [
                {
                  name: '删除',
                  type: 'pop',
                  popTitle: '确认是否删除吗?',
                  onClick: () => {
                    that.relatedProductsKeys.splice(index, 1)
                    that.relatedProducts.splice(index, 1)
                    that.$forceUpdate()
                  },
                },
              ]
            },
          },
        ],
        dataSource: this.relatedProducts,
      }
    },
    onClickAddRelatedProducts() {
      apiTool.showModal({
        width: '950px',
        title: '关联商品',
        view: RelatedProductsModel,
        viewProps: {
          selectedRowKeys: this.relatedProductsKeys,
          // businessIdArr: this.businessIdArr,
        },
        success: ({ data, setHidden }) => {
          let allData = unionBy(this.relatedProducts, data.selectedRows, 'businessId')
          this.relatedProductsKeys = data.selectedRowKeys
          this.relatedProducts = allData
          this.$message.success('已添加列表')
          setHidden()
          this.$forceUpdate()
        },
        // foot: () => [],
      })
    },
    renderRelatedProductsFormTop() {
      return (
        <div class="flex items-center">
          <div class="flex-1">关联商品</div>
          <a-dropdown>
            <a-button ghost type="primary" onClick={this.onClickAddRelatedProducts}>
              关联商品
            </a-button>
          </a-dropdown>
        </div>
      )
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            postAction('/farmRecommendCommunity/save', {
              ...data,
              productList: this.relatedProducts || [],
              upDown: '0',
              bannerType: '0',
            }).then(() => {
              setTimeout(() => {
                this.$router.back()
              }, 500)
            })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPost.call(this, {
              url: `/config/farmConfigInvest/delete?id=${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        typeData={{
          regionCode: this.regionCode,
        }}
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm7(), this.getRelatedProductsForm()]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.related-products-form {
  &-img {
    width: 50px;
    height: 50px;
  }
}
</style>
